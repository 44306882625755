<template>
  <el-card shadow="hover" header class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">站点信息</div>

      <el-button type="primary" @click="handleSaveShopData('ruleForm')" size="small">保存</el-button>
    </div>

    <el-form :model="ruleForm" ref="ruleForm" label-width="180px" class="demo-ruleForm" :rules="rules">
      <el-form-item label="站点名称" prop="shop_name">
        <el-input v-model="ruleForm.shop_name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item v-if="shopInfo.shop_type == 7" label="商城前台网址" prop="region">
        <el-image v-if="ruleForm.app_code_url" style="width: 120px;" :src="ruleForm.app_code_url"
          :preview-src-list="[ruleForm.app_code_url]">
        </el-image>
        <span v-else>请到【小程序设置】菜单页面中进行设置。设置通过后，才可展示出前台网址二维码</span>
      </el-form-item>
      <el-form-item v-else label="站点网址" prop="region">
        <span>https://{{ ruleForm.domain_name + $url }}</span>
      </el-form-item>
      <el-form-item label="创建时间">
        <span>{{ ruleForm.shop_createtime | unixToDate('yyyy-MM-dd hh:mm') }}</span>
      </el-form-item>
      <el-form-item label="客服电话" prop="customer_service">
        <el-input v-model="ruleForm.customer_service" style="width: 300px"></el-input>
      </el-form-item>
      <!-- <el-form-item label="默认地区" prop="regionValue">
        <x-address-select :value.sync="ruleForm.regionValue" width="300px"/>
      </el-form-item>
      <el-form-item label="是否显示市场价" prop="show_mktprice">
        <el-switch v-model="ruleForm.show_mktprice"></el-switch>
      </el-form-item>
      <el-form-item label="是否显示购买须知" prop="show_purchasing_notice_switch">
        <el-switch v-model="ruleForm.show_purchasing_notice_switch"></el-switch>
      </el-form-item> -->
      <el-form-item label="是否显示客服电话" prop="show_customer_service">
        <el-switch v-model="ruleForm.show_customer_service" :disabled="false"></el-switch>
      </el-form-item>
      <el-form-item label="商城状态" prop="shop_disable">
        <el-switch v-model="ruleForm.shop_disable" :disabled="false"></el-switch>
      </el-form-item>
      <el-form-item label="是否开通售后功能" prop="enable_aftersale">
        <el-switch v-model="ruleForm.enable_aftersale"></el-switch>
      </el-form-item>
      <el-form-item label="是否开启手动换货功能" prop="manual_exchange_goods">
        <el-switch v-model="ruleForm.manual_exchange_goods"></el-switch>
      </el-form-item>
      <el-form-item label="是否前台显示商城名称" prop="show_shop_name">
        <el-switch v-model="ruleForm.show_shop_name"></el-switch>
      </el-form-item>
      <el-form-item v-if="shopInfo.shop_type == 7" label="是否开通小程序在线客服" prop="show_purchasing_notice_switch">
        <el-switch v-model="ruleForm.customer_service_show" style="margin-right:40px"></el-switch>
      </el-form-item>
      <el-form-item v-if="shopInfo.shop_type == 7" label="消息通知方式设置" prop="show_purchasing_notice_switch">
        <el-checkbox v-model="ruleForm.send_sms_code" label="短信通知用户"></el-checkbox>
        <el-checkbox v-model="ruleForm.push_wx_model_notice" label="微信公众号消息通知用户"></el-checkbox>
        <el-tooltip style="max-width: 200px" effect="dark" placement="top">
          <i class="el-icon-warning"></i>
          <div slot="content">
            需对应小程序商城配置公众号后，方可正常使用该项功能
          </div>
        </el-tooltip>
      </el-form-item>
      <el-form-item v-else label="是否开通在线客服" prop="show_purchasing_notice_switch">
        <el-switch v-model="ruleForm.customer_service_show"
          style="margin-right:40px"></el-switch>提示：开通后，商城将显示“在线客服”按钮，并跳转至指定客服链接。
      </el-form-item>
      <el-form-item v-if="ruleForm.customer_service_show && shopInfo.shop_type !== 7" label="客服跳转链接"
        prop="customer_service_link">
        <el-input v-model="ruleForm.customer_service_link" style="width: 300px"></el-input>
      </el-form-item>
      <!-- <el-form-item label="商城货币单位设置" prop="monetary_unit">
        <el-input v-model="ruleForm.monetary_unit" style="width: 300px">
          <template slot="prepend">
            <span style="margin-right: 20px">￥</span>
          </template>
<template slot="prepend">转换为</template>
</el-input>
</el-form-item> -->

      <el-form-item label="登录页图片设置" prop="background_img">
        <el-upload v-show="!ruleForm.background_img" ref="uploadFile" style="display: flex;"
          :action="`${MixinUploadApi}?scene=goods`" list-type="picture-card" :limit="1" :before-upload="beforeUpload"
          :on-success="handleUploadSuccess">
          <i class="el-icon-plus"></i>
          <template slot="tip">
            <div style="line-height: 148px;">请上传16：9，大小不超10MB，格式为jpg/png的图片。</div>
          </template>
        </el-upload>
        <div class="background-img" v-if="ruleForm.background_img">
          <img :src="ruleForm.background_img" alt />
          <div class="mantle">
            <i class="el-icon-delete" @click="handleUploadRemove"></i>
            <el-button round size="mini" class="btn-change-img" @click="handleChangeImg">更换图片</el-button>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="设置登录页按钮颜色" prop="btn_color">
        <el-button type="primary" size="mini" @click="ruleForm.btn_color='#FFFFFF'">重置</el-button>
        <el-color-picker v-model="ruleForm.btn_color" popper-class="set-background-colo-picker" />
      </el-form-item> -->

      <el-form-item label="网页版权声明" prop="copyright">
        <el-input v-model="ruleForm.copyright" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label prop="name">
        <div style="line-height: 1.7">
          <span>此信息将显示在网页底部</span>
          <br />
          <span style="padding-top: 15px; color: gray">{{ ruleForm.copyright }}</span>
        </div>
      </el-form-item>
      <el-form-item label="商城备注信息">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 7 }" maxlength="100" :rows="2"
          placeholder="请输入100字以内的备注信息" v-model="ruleForm.shop_remark" style="width: 300px"></el-input>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as API_account from "@/api/account";
import XAddressSelect from "../../../components/x-address-select/x-address-select";

export default {
  name: "person",
  components: { XAddressSelect },
  data () {
    return {
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      params: {
        shop_id: undefined,
        shop_name: undefined,
        domain_name: undefined,
        shop_createtime: undefined,
        customer_service: undefined,
        show_mktprice: undefined,
        enable_aftersale: undefined,
        shop_disable: undefined,
        show_customer_service: undefined,
        manual_exchange_goods: undefined,
        show_shop_name: undefined,
        copyright: undefined,
        monetary_unit: undefined,
        region: undefined,
        shop_remark: undefined
      },
      ruleForm: {
        shop_id: undefined,
        shop_name: undefined,
        domain_name: undefined,
        shop_createtime: undefined,
        customer_service: undefined,
        show_mktprice: undefined,
        enable_aftersale: undefined,
        shop_disable: undefined,
        show_customer_service: undefined,
        manual_exchange_goods: undefined,
        show_shop_name: undefined,
        copyright: undefined,
        monetary_unit: undefined,
        category_button: undefined,
        customer_service_show: undefined,
        send_sms_code: undefined,
        push_wx_model_notice: undefined,
        customer_service_link: undefined,
        regionValue: [],
        show_purchasing_notice_switch: undefined,
        // task 691新加
        background_img: "",
        btn_color: undefined,
        shop_remark: undefined,
        app_code_url: undefined
      },
      rules: {
        // 目前要求非必填
        // regionValue: [
        //   {
        //     required: true,
        //     validator: (rule, value, callback) => {
        //
        //       if (!value || value.length === 0) {
        //         return callback('请选择默认地址');
        //       }
        //
        //       callback();
        //     },
        //     trigger: "blur",
        //   },
        // ],
        shop_name: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        customer_service: [
          {
            // required: true,

            validator: (rule, value, callback) => {
              // let reg = /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/;
              // if (value && !reg.test(value)) {
              //   return callback('座机号或手机号格式不对');
              // }
              callback();
            },
            trigger: "blur"
          }
        ],
        copyright: [
          {
            required: true,
            message: "请输入网页版权声明",
            trigger: "blur"
          }
        ],
        monetary_unit: [
          {
            min: 1,
            max: 2,
            message: "字数不超2个字符",
            trigger: "blur"
          }
        ],
        customer_service_link: [
          {
            required: true,
            message: "开通在线客服功能，必须要填写客服跳转链接",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    "ruleForm.customer_service_show" () {
      this.rules.customer_service_link[0].required = this.ruleForm.customer_service_show;
    }
  },
  created () {
    this.getShopMsg();
  },
  computed: {},
  methods: {
    handleSaveShopData (formName) {
      this.params = {};

      this.$refs[formName].validate(valid => {
        if (valid) {
          this.params.shop_id = this.ruleForm.shop_id;
          this.params.shop_name = this.ruleForm.shop_name;
          this.params.customer_service = this.ruleForm.customer_service;
          this.params.show_mktprice = this.ruleForm.show_mktprice ? 1 : 0;
          this.params.enable_aftersale = this.ruleForm.enable_aftersale ? 1 : 0;
          this.params.show_purchasing_notice_switch = this.ruleForm
            .show_purchasing_notice_switch
            ? 1
            : 0;
          this.params.shop_disable = this.ruleForm.shop_disable
            ? "OPEN"
            : "CLOSED";
          this.params.show_customer_service = this.ruleForm.show_customer_service ? 1 : 0;
          this.params.manual_exchange_goods = this.ruleForm.manual_exchange_goods ? 1 : 0;
          this.params.show_shop_name = this.ruleForm.show_shop_name ? 1 : 0;
          this.params.copyright = this.ruleForm.copyright;
          this.params.shop_remark = this.ruleForm.shop_remark;
          this.params.monetary_unit = this.ruleForm.monetary_unit;
          this.params.category_button = this.ruleForm.category_button ? 1 : 0;
          this.params.customer_service_show = this.ruleForm
            .customer_service_show
            ? 1
            : 0;
          this.params.send_sms_code = this.ruleForm.send_sms_code ? 1 : 0;
          this.params.push_wx_model_notice = this.ruleForm.push_wx_model_notice ? 1 : 0;
          this.params.customer_service_link = this.ruleForm.customer_service_link;
          this.params.btn_color = this.ruleForm.btn_color;
          this.params.background_img = this.ruleForm.background_img;

          if (this.ruleForm.regionValue) {
            this.params.region = this.ruleForm.regionValue[
              this.ruleForm.regionValue.length - 1
            ];
          }

          API_account.editShopSetting(this.params).then(res => {
            this.getShopMsg();
            this.$message.success("操作成功");
          });
        } else {
          return false;
        }
      });
    },
    getShopMsg () {
      API_account.getShopmsg().then(res => {
        Object.keys(this.ruleForm).forEach(key => {
          let value = res[key];

          if (key === "customer_service_show") {
            value = res.customer_service_show === 1;
          }
          if (key === "send_sms_code") {
            value = res.send_sms_code === 1;
          }
          if (key === "push_wx_model_notice") {
            value = res.push_wx_model_notice === 1;
          }
          if (key === "category_button") {
            value = res.category_button === 1;
          }

          if (key === "show_mktprice") {
            value = res.show_mktprice === 1;
          }

          if (key === "show_customer_service") {
            value = res.show_customer_service === 1;
          }

          if (key === "manual_exchange_goods") {
            value = res.manual_exchange_goods === 1;
          }

          if (key === "show_shop_name") {
            value = res.show_shop_name === 1;
          }

          if (key === "enable_aftersale") {
            value = res.enable_aftersale === 1;
          }

          if (key === "show_purchasing_notice_switch") {
            value = res.show_purchasing_notice_switch === 1;
          }

          if (key === "shop_disable") {
            value = res.shop_disable === "OPEN";
          }

          if (!value && key === "regionValue") {
            if (res.web_shop_address) {
              value = [
                res.web_shop_address.province_id,
                res.web_shop_address.city_id,
                res.web_shop_address.county_id,
                res.web_shop_address.town_id
              ].filter(id => !!id);
            }
          }

          this.ruleForm[key] = value;
        });

        this.ruleForm = {
          ...this.ruleForm
        };

        this.$forceUpdate();
      });
    },
    // 上传图片
    // 上传之前校验
    beforeUpload (file) {
      let result = true;
      const fileType = file.name.split(".")[1];
      const fileSize = parseFloat((file.size / 1024 / 1024).toFixed(2));
      if (fileType !== "jpg" && fileType !== "png") {
        result = false;
      }
      if (fileSize > 10) {
        result = false;
      }
      if (!result) {
        this.$message.error("请按照提示信息上传图片");
        return false;
      }
    },
    // 上传成功回调
    handleUploadSuccess (response, file, fileList) {
      this.ruleForm.background_img = response.url;
    },
    // 删除文件
    handleUploadRemove () {
      this.ruleForm.background_img = "";
      this.$refs.uploadFile.clearFiles();
    },
    // 更换图片
    handleChangeImg () {
      this.$refs.uploadFile.clearFiles();
      this.$refs.uploadFile.$children[1].$refs.input.click();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__content {
  .background-img {
    width: 167px;
    height: 167px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .mantle {
      width: 167px;
      height: 167px;
      display: none;
      position: absolute;
      top: 0;
      background-color: black;
      opacity: 0.5;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 23px;
        position: relative;
        left: 85%;
      }

      .btn-change-img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: 15%;
        background-color: black;
        opacity: 0.6;
        color: white;
      }
    }
  }

  .background-img:hover {
    .mantle {
      display: block;
    }
  }

  .el-color-picker {
    height: 30px;
    margin-left: 10px;
    vertical-align: middle;

    .el-color-picker__trigger {
      width: 90px;
      height: 30px;
      padding: 0;
      border: 0;
      border-radius: 5px;
    }

    .el-color-picker__color {
      border: 1px solid #cccccc;
    }

    .el-icon-arrow-down:before {
      content: "";
    }
  }
}

.content {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
